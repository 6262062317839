
import { PerspectiveCamera } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRef } from "react"
import { minmax } from "../utils";

export default function CompositionCamera(props) {
  const camera = useRef();

  const group = useRef();



  const point = useRef({ x: 0, y: 0 });

  useFrame(({ mouse }) => {

    if (group.current) {
      point.current.x = minmax(mouse.x * 0.05, -0.15, 0.15);
      point.current.y = minmax(mouse.y * 0.05, -0.15, 0.15);

      group.current.rotation.z +=
        (point.current.x - group.current.rotation.z) * 0.05;
      group.current.rotation.y +=
        (point.current.x - group.current.rotation.y) * 0.05;
      group.current.rotation.x +=
        (point.current.y - group.current.rotation.x) * 0.05;

      camera.current.position.setZ(7. + mouse.y);
      // camera.current.rotation.y +=
      //   (point.current.x - camera.current.rotation.y) * 0.5;
      // camera.current.position.y +=
      //   (point.current.y - camera.current.position.y) * 0.5;
    }
  });

  return (
    <group ref={group} {...props}>
      <group ref={camera}
        position={[0, -0.2, 0]}
        rotation={[-0.1, 0, 0]}
      >
        <PerspectiveCamera
          makeDefault
          {...props}
        />

        {props.children}
      </group>
    </group>
  );
}