import React, { useState } from "react"

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

function useForm(data) {
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const handleSubmit = e => {
        setError();
        setLoading(true);
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...data })
        })
            .then(() => setSubmitted(true))
            .catch(error => setError(error))
            .finally(() => setLoading(false));

        e.preventDefault();
    };

    return {
        submitted,
        handleSubmit,
        error,
        loading,
    }

}

export default function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { handleSubmit, submitted } = useForm({ name, email, message })


    if (submitted) {
        return <div>
            <div>
                <h1 style={{ fontSize: `10rem`, margin: 0 }}>🥂</h1>
                <p>You've started your journey to create a digital experience. We will get back to you shortly!</p>
            </div>
        </div>
    }

    return <div>
        <h1>Start building a new experience</h1>
        <form onSubmit={handleSubmit}>
            <p>
                <label>
                    <input placeholder="Name" type="text" name="name" required value={name} onChange={(e) => setName(e.currentTarget.value)} />
                </label>
            </p>
            <p>
                <label>
                    <input placeholder="Email" type="email" name="email" required value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                </label>
            </p>
            <p>
                <label>
                    <textarea placeholder="Message" name="message" required value={message} onChange={(e) => setMessage(e.currentTarget.value)} />
                </label>
            </p>
            <p>
                <label>
                    <input type="checkbox" name="opt-in" /> Add me to your mailing list.
                </label>
            </p>
            <p>
                <label>
                    <input type="checkbox" name="privacy" required /> Acknowledge; Cybermancers will keep the details of this message private.
                </label>
            </p>
            <p>
                <button type="submit">Send</button>
            </p>
        </form></div>
}