import React from 'react'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { useDetectGPU } from '@react-three/drei'


export default function FX() {
  const GPUTier = useDetectGPU()

  if (GPUTier.isMobile) return;

  return (
    <EffectComposer>
      <Bloom luminanceThreshold={1} luminanceSmoothing={0.6} height={512} />
    </EffectComposer>
  )
}