import React, { useContext, useEffect, useState } from "react";

let waiting = false;
const AudioCTX = React.createContext([{}, () => { }]);
function AudioContextProvider({ children }) {
  const [context, setContext] = useState(null);

  useEffect(() => {
    async function setupVolume() {
      const context = new AudioContext();
      await context.audioWorklet.addModule("vumeter_worklet.js");
      await context.audioWorklet.addModule("phasescope_worklet.js");
      context.id = Math.random();
      context.suspend();
      setContext(context);
      waiting = false;
    }
    if (context === null && !waiting) {
      waiting = true;
      setupVolume();
    }
  }, [context]);
  if(!context) return null;
  return <AudioCTX.Provider value={context}>{children}</AudioCTX.Provider>;
}

function useAudioContext() {
  return useContext(AudioCTX);
}

export { AudioCTX, AudioContextProvider, useAudioContext };
