import React, {useRef} from "react";
import FX from "./FX";
import CompositionCamera from "./components/CompositionCam";
import { useDetectGPU } from "@react-three/drei";

const Hero = React.lazy(() => import('./components/Hero'));

function LightRig() {
  const ambient = useRef();
  const GPUTier = useDetectGPU()
  return <>
    <ambientLight ref={ambient} intensity={1} />
    {!GPUTier.isMobile && <fog attach="fog" args={["#000065", 5, 18]} />}
  </>
}

export default function App() {

  return <>
    <Hero />
    <LightRig />
    <FX />
    <CompositionCamera position={[0, 0, -2]} />
  </>

}