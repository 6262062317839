import { createRoot } from 'react-dom/client';
import React, { Suspense, useRef, useState } from "react";

import "./styles.scss";

import { AudioContextProvider } from "./hooks/audioContext";
import { ConfigProvider } from "./hooks/configContext";

import { Loader } from "@react-three/drei";
import App from './App';
import ContactForm from './components/Contact';
import Portal from './components/Portal';

const Services = React.lazy(() => import('./components/state/Services'));
const TransferContext = React.lazy(() => import('./components/state/TransferContext'));

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


function About() {
  return <div>
    <h1>Cybermancers create interactive digital experiences using web-first technology.</h1>
    <h2>What is a digital experience, and why would I need one?</h2>
    <p>Some things are better left to first-hand experience. How often have you told someone they needed to see it to believe it?</p>
    <p>Describe this website in words. A silver person performs weird glitchy dances while speakers rumble and embers fly around. All the elements are accounted for, but reading it isn't the same experience. It gets lost in translation. Maybe a poet could do a better job.
</p>
    <p>A digital experience is more like a theme park ride. It's something you participate in rather than watch from the sidelines.</p>
    <p>If you have something to say and words won't do it justice, create a digital experience.</p>
    <p><strong>To get started, fill out our contact form or </strong> <a href="https://contra.com/cybermancers" target="_blank" rel="noreferrer">hire me on Contra</a>.</p>
    
  </div>
}


function ContactUs() {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  return <>
    <button onClick={() => setOpen(!open)}>{open ? 'Close' : 'Our Service'}</button>
    <Portal pointerEvents={open}>
      <div ref={ref} className={`page grid two-columns ${open ? 'show' : 'hide'}`}>
        <About />
        <ContactForm />
      </div>
    </Portal>
  </>
}



root.render(<React.StrictMode>
  <header>
    <h1>CYBERMANCERS</h1>
    <ContactUs />
  </header>
  <Loader
    containerStyles={{ zIndex: 1 }}
    dataInterpolation={(p) => `${p.toFixed(0)}`} // Text
  />
  <Suspense fallback={null}>
    <ConfigProvider>
      <AudioContextProvider>
        <Services>
          <TransferContext>
            <App />
          </TransferContext>
        </Services>
      </AudioContextProvider>
    </ConfigProvider>
  </Suspense>
</React.StrictMode>);

